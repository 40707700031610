/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	var Sage = {
		// All pages
		'common': {
			init: function () {

			/*
			* Growers iframe videos
			*
			* Uses the youtube api to create videos and control play functions through custom elements
			*/

				// Get video blocks and create global players array
				var videoBlocks = document.getElementsByClassName('grower-video'),
					totalVideos = videoBlocks.length,
					currentVideo = 0,
					players = [];

				// Run API ready check on window object
				window.onYouTubePlayerAPIReady = function(){
					if(videoBlocks){
						// Create array of data we need for youtube videos
						var playerList = [];
						for(var i = 0; i < videoBlocks.length; i++){
							// For each video get the player element, index and youtube videoId
							playerList.push({
								'player': 'iframe-embed-' + videoBlocks[i].getAttribute('data-video'),
								'id': videoBlocks[i].getAttribute('data-video'),
								'youtube': videoBlocks[i].getAttribute('data-youtube'),
							});
						}
						for(var y = 0; y < playerList.length; y++) {
							// Create the iframe object from the current div and details
							createPlayer(playerList[y]);
						}
				 	} else {
						return;
					}
				};

				 function createPlayer(playerInfo) {
					// Player object and details
				 	var player = new YT.Player(playerInfo.player, {
						height: '640',
				        width: '360',
				        videoId: playerInfo.youtube,  // youtube video id
				        playerVars: {
				            'autoplay': 0,
				            'rel': 0,
				            'showinfo': 0,
							'controls': 2,
							'enablejsapi': 1,
				        },
				 		events: {
				 			// call this function when player is ready to use
							onReady: function (){
								// Iterate counter for detecting last video
								currentVideo++;

								// Get play button and container
								var playButton = document.getElementById('video-play-' + playerInfo.id);
								var parentContainer = document.getElementById('grower-video-' + playerInfo.id);

								// Add click function
								playButton.addEventListener('click', function() {
									player.playVideo();
									parentContainer.classList.add('video-active');
									$(this).fadeOut();
								});

								// Remove loading class on final loop
								if (currentVideo === totalVideos && $('.growers-videos-list').hasClass('loading')){
									$('.growers-videos-list').removeClass('loading');
								}

							},
							// Call this function when the players state changes
							'onStateChange': onPlayerStateChange
				 		}
				 	});
					players.push(player);
				 }

				 function onPlayerStateChange(event){

					 // Pause all players except one currently playing
					 if (event.data === YT.PlayerState.PLAYING) {
			            var temp = event.target.a.src;
			            for (var i = 0; i < players.length; i++) {
			                if (players[i].a.src !== temp){
			                    players[i].pauseVideo();
							}
			            }
			        }

				 }

				/**
				 * Sticky Nav
				 */
				var mainHeader = $('#main-header'),
						mainFooter = $('#main-footer'),
						mainContainer = $('.main');

				// Sticky function
				function stickyNav() {
					var scrollTop = $(window).scrollTop();
					if (scrollTop > 0) {
						mainHeader.addClass('sticky');
					} else {
						mainHeader.removeClass('sticky');
					}
				}

				// Run on doc ready
				if ($(document).height() >= $(window).height() + mainHeader.height() + mainFooter.height()) {
					stickyNav();
				}

				// Run on Scroll
				$(window).scroll(function () {
					if ($(document).height() >= $(window).height() + mainHeader.height() + mainFooter.height()) {
						stickyNav();
					}
				});

				/**
				 * Main Min height
				 */
				function set_main_min_height() {
					minHeight = $(window).height() - mainHeader.height() - mainFooter.height();
					mainContainer.css('min-height', minHeight);
				}

				set_main_min_height();

				$(window).resize(function () {
					set_main_min_height();
				});


				/**
				 * Mobile Nav
				 */
				$('.navbar-toggle').click(function () {
					$(this).toggleClass('toggled');
				});

				$('.navmenu').on('hide.bs.offcanvas', function () {
					$('.navbar-toggle').removeClass('toggled');
				});


				/**
				 * Magic Arrow
				 */
				var $el, leftPos, newWidth,
						$mainNav = $(".primary-nav");

				$mainNav.append("<li id='magic-line'><span id='magic-line-inner'></span></li>");
				var $magicLine = $("#magic-line");

				if ($(".primary-nav .current_page_item").length === 0) {
					$magicLine.addClass('hidden');
				} else {
					if ($("body.home").length === 1) {
						$magicLine.addClass('hidden');
					}

					$magicLine
							.width($(".current_page_item").width())
							.css("left", $(".current_page_item a").position().left)
							.data("origLeft", $magicLine.position().left)
							.data("origWidth", $magicLine.width());

					// Only activate if we're not using a touch device.
					// This was forcing the user to click twice to activate a link on ipad
					if (!Modernizr.touch) {
						$(".primary-nav li a").hover(function () {
							$el = $(this);
							leftPos = $el.position().left;
							newWidth = $el.parent().width();
							$magicLine.stop().animate({
								left: leftPos,
								width: newWidth
							});
						}, function () {
							$magicLine.stop().animate({
								left: $magicLine.data("origLeft"),
								width: $magicLine.data("origWidth")
							});
						});
					}
				}

				/**
				 * Smooth scroll on-page anchors
				 */
				$('.on-page-link').click(function () {
					$('.on-page-link.active-section').removeClass('active-section');
					$(this).addClass('active-section');
					if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
						var target = $(this.hash);
						target = target.length ? target : $('[id=' + this.hash.slice(1) + ']');
						if (target.length) {
							var targetScroll = target.offset().top - 250;
							$('html,body').animate({
								scrollTop: targetScroll
							}, 1000);
							return false;
						}
					}
				});

				$(document).on('click','.js-videoPoster',function(e) {
				  e.preventDefault();
				  var $poster = $(this);
				  var $wrapper = $poster.closest('.js-videoWrapper');
				  videoPlay($wrapper);
				});

				// play the targeted video (and hide the poster frame)
				function videoPlay($wrapper) {
				  var $iframe = $wrapper.find('.js-videoIframe');
				  var src = $iframe.data('src');
				  // hide poster
				  $('.videoPoster').fadeOut();
					$('.fa-play-circle').fadeOut();
				  // add iframe src in, starting the video
				  $iframe.attr('src',src);
				}

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				$('.flexslider-almonds .slides').on('click', 'li', function () {
					$('.flexslider-almonds .slides li').removeClass('flex-active-slide');
					$(this).addClass('flex-active-slide');
				});

				$(window).scroll(function () {
					if ($(this).scrollTop() >= 500) { // this refers to window
						if ($(this).scrollTop() >= $(document).height() - $(window).height() - 500) {
							$('.bouncing-arrow').addClass('shown');
						} else {
							$('.bouncing-arrow').removeClass('shown');
						}
						$('.bouncing-arrow').delay(1000).addClass('backup').attr('href', '#');
					} else {
						$('.bouncing-arrow').removeClass('backup shown').attr('href', '#home-step-2');
					}
				});

				var stellarActive = false;

				function stellar_parallax_blocks() {
					/*
					 if ($(window).width() >= 991 && !Modernizr.touch){

					 $.stellar({
					 horizontalScrolling: false,
					 verticalScrolling: true,
					 horizontalOffset: 0,
					 verticalOffset: 400,
					 responsive: true,
					 scrollProperty: 'transform',
					 positionProperty: 'position',
					 parallaxBackgrounds: true,
					 parallaxElements: false,
					 hideDistantElements: true,
					 hideElement: function($elem) { $elem.hide(); },
					 showElement: function($elem) { $elem.show(); }
					 });

					 $(window).data('plugin_stellar').init();
					 stellarActive = true;

					 } else {

					 if (stellarActive === true){
					 $(window).data('plugin_stellar').destroy();
					 stellarActive = false;
					 }

					 }
					 */
				}

				// Mobile Slider
				$(window).load(function () {
					stellar_parallax_blocks();
				});

				$('.flexslider-mobile-home-slider').flexslider({
					animation: "slide",
					slideshow: true,
					prevText: "",
					nextText: ""
				});

				/**
				 * Main Intro Block
				 */
				var mainIntro = $('#main-intro'),
						headerHeight = $('#main-header').innerHeight(),
						windowHeight = $(window).height();

				function resize_main_intro() {
					if (mainIntro.length) {
						mainIntroBanner = mainIntro.find('.banner-home');
						setIntroHeight = windowHeight - headerHeight;
						mainIntroBanner.css('min-height', setIntroHeight);
					}
				}

				var $scene = $('.seasons-images').parallax();

				//Custom sliding accordion for homepage
				$.fn.accordion = function (opts) {
					this.each(function () {
						var t = $(this),
								m = t.children(),
								c = m.children();

						m.on("hover", "li", function () {
							$(this).siblings().removeClass('active'); // remove the active class from any other li slides
							$(this).addClass('active'); // add the active class to the li which has been selected
						});

					});
				};

				//Fire image accordion
				$(".accordion").accordion();

				// trigger parallax test object on scroll for homepage
				$(window).trigger('resize').trigger('scroll');

				/**
				 * Updates the weather in the dom
				 *
				 * @param {object} weather The weather data
				 * @returns {void}
				 */
				function setWeather(weather) {
					var html = '<p class="temp">' + weather.temp + '&deg;' + weather.units.temp + '</p>';
					//var localTime = 'California local time: ' + '<span class="time">' + weather.title.split(' ')[5] + weather.title.split(' ')[6] + '</span>';
					$(".weather").html(html);

					// Season switcher
					var thisDate = weather.forecast[0].date;
					var monthfield = thisDate.split(' ')[1];

					// Set opacity for season selector
					$('.the-shaker').css('opacity', 0.6);

					if (monthfield === ('Oct') || ('Nov') || ('Dec') || ('Jan') || ('Feb')) {
						season = "winter";
						$('.winter').addClass('active').show();
						$('.the-shaker').css('opacity', 1).addClass('selected');
						$('.the-shaker').children('.copy').css('opacity', 1);
					} else if (monthfield === ('March') || ('April') || ('May')) {
						season = "spring";
						$('.spring').addClass('active').show();
					} else if (monthfield === ('Jun') || ('Jul') || ('Aug')) {
						season = "summer";
						$('.summer').addClass('active').show();
					} else {
						season = "autumn";
						$('.autumn').addClass('active').show();
					}
					$(".season").html(season);
				}

				/**
				 * Loads weather from the json file
				 *
				 * @returns {void}
				 */
				function loadWeather() {
					$.ajax({
						url: '/wp-content/themes/bluediamond/temperature.json',
						type: 'GET',
						dataType: 'json',
						success: function(data) {
							setWeather(data);
						},
						error: function() {
							$(".weather").html('<p>Unknown Temperature</p>');
						}
					});
				}

				/**
				 * Saves weather to the json file
				 *
				 * @param {object} data The weather json to save
				 * @returns {void}
				 */
				function saveWeather(data) {
					$.ajax({
						url: '/wp-admin/admin-ajax.php',
						type: 'POST',
						dataType: 'html',
						data: {
							action: 'save_temperature',
							temp: data.temp,
							unit: data.units.temp,
							date: data.forecast[0].date
						}
					});
				}

				// If simpleWeather crashed, load it anyway

                if ($('#home-temp').length){

    				loadWeather();

                    // If you get the error: cannot read property 'channel' of null, follow the fix below:
                    // https://stackoverflow.com/questions/34920765/cannot-read-property-channel-of-null-with-simpleweather-js

    				$.simpleWeather({
    					location: 'Sacramento, CA',
    					woeid: '',
    					unit: 'c',
    					success: function (data) {
    						setWeather(data);
    						saveWeather(data);
    					},
    					error: function (error) {
    						loadWeather();
    					}
    				});

                }


				// Initialliaze product coverflow carousel
				var coverflow = $("#coverflow").flipster({
					style: 'carousel',
					spacing: '.1',
					nav: true,
					buttons: true,
					start: 0,
					scrollwheel: false,
					autoplay: false,
					loop: true,
					touch: true,
					keyboard: true,
					click: true
				});

				$(window).load(function () {
					$('#products-slider-inner').addClass('loaded');
					$('#products-slider .loading-section').remove();
				});

				$('.flow-filter').click(function () {
					var filterBy = '.' + $(this).attr("value");
					$('.product').append();
					$(filterBy).detach();
					flowProducts();
				});

				// Initialliaze Social Feed
				$('#coverflow-social .flip-items').socialfeed({
					//FACEBOOK
					facebook: {
						accounts: ['@BlueDiamondUK'], //Array: Specify a list of accounts from which to pull wall posts
						limit: 1, //Integer: max number of posts to load
						access_token: '452555554929155|2b9e1bb5c0474eccddea743062b5eb16' //String: "APP_ID|APP_SECRET"
					},
					//INSTAGRAM
					// instagram: {
					// 	accounts: ['&249688550'], //Array: Specify a list of accounts from which to pull posts
					// 	limit: 1, //Integer: max number of posts to load
					// 	//client_id: 'b940119388c24009abfef76a759332a1', //String: Instagram client id
					// 	access_token: '402031536.de559c6.069877c1c6b1497396bd27a01afcc7b7'
					// },
					// GENERAL SETTINGS
					length: 100, //Integer: For posts with text longer than this length, show an ellipsis.
					template: "wp-content/themes/bluediamond/template.html",
					show_media: true,
					callback: function () {                          //Function: This is a callback function which is evoked when all the posts are collected and displayed

					}
				});

				setTimeout(function () {
					$(window).load(function () {
						$('.social-feed-container').addClass('loaded');
						$('.social-feed-wrap .loading-section').remove();
						callGallery();
					});
				}, 500);

				function callGallery() {
					// Homepage flexslider
					if ($(window).width() > 768) {
						$('#coverflow-social').flipster({
							style: 'carousel',
							spacing: '-.5',
							nav: false,
							buttons: true,
							start: 0,
							scrollwheel: false,
							autoplay: false,
							loop: false,
							touch: true,
							keyboard: true,
							click: true
						});
					}
				}

				/**
				 * Initiate growers and almonds sliders
				 */
				function slider(elemname, minIt, maxIt) {
					// store the slider in a local variable
					var $window = $(window),
							flexslider2, flexslider;

					// tiny helper function to add breakpoints
					function getGridSize() {
						return (window.innerWidth <= 767) ? 1 :
								(window.innerWidth <= 991) ? minIt : maxIt;
					}

					// Syntax highlighter
					if (typeof SyntaxHighlighter === 'function') {
						$(function () {
							SyntaxHighlighter.all();
						});
					}

					// Initiate Slider
					$(elemname).flexslider({
						animation: "slide",
						animationLoop: false,
						directionNav: true,
						controlNav: false,
						itemWidth: 185,
						prevText: "",
						nextText: "",
						itemMargin: 0,
						minItems: getGridSize(), // use function to pull in initial value
						maxItems: getGridSize(), // use function to pull in initial value
						start: function (slider2) {
							flexslider2 = slider2;
						}
					});

					// check grid size on resize event
					$window.resize(function () {
						$(elemname).flexslider(0);
						var gridSize = getGridSize();
						flexslider2.vars.minItems = gridSize;
						flexslider2.vars.maxItems = gridSize;
						flexslider2.setup();
					});

				}

				if ($('.flexslider-almonds').length) {
					slider('.flexslider-almonds', 3, 6);
				}
				if ($('.flexslider-growers').length) {
					slider('.flexslider-growers', 3, 6);
				}

			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'faq': {
			init: function () {
				$('#faq-search').keyup(function () {
					var el = $(this),
						val = el.val().toLowerCase(),
						panel = $('.panel-group .panel');

					$('.nav-tabs li').addClass('searching');
					panel.hide();

					panel.each(function () {
						var panelEach = $(this),
							text = panelEach.find('.panel-title a').text().toLowerCase();

						if (text.indexOf(val) !== -1) {
							panelEach.show();
						}
					});

					globalTimeout = setTimeout(function () {
						$('.nav-tabs li').removeClass('searching');
					}, 1000);
				});

				$('#faq-search-form').on('submit', function (e) {
					hideKeyboard($("#faq-search"));
					e.preventDefault();
					return false;
				});

				$('#faq-search-form').on('keyup keypress', function (e) {
					var code = e.keyCode || e.which;
					if (code === 13) {
						hideKeyboard($("#faq-search"));
						e.preventDefault();
						return false;
					}
				});

			}
		},
		'our_kitchen': {
			init: function () {
				// Recipes Isotope
				var $grid = $('.items-block');
				$grid.isotope({
					itemSelector: '.item',
					masonry: {},
					onLayout: function () {
						$(window).trigger("scroll");
					}
				});

				$('.filter').click(function () {
					var $el = $(this),
						$elName = $el.attr('data-name'),
						$elVal = $el.attr('value'),
						$elLabel = '<span id="filter-label-' + $elVal + '">' + $elName + '</span>',
						$dropWrap = $el.closest('.dropdown-selector'),
						$dropFilters = $dropWrap.find('.filter'),
						$activeItems = $dropWrap.find('.selector-active-items'),
						filterItems = '';

					// Toggle active class on clicked item
					$el.toggleClass('active');

					// Loop through current filter block items and remove class of all but clicked
					$dropFilters.each(function () {
						$elEach = $(this);
						if ($elEach.attr('value') !== $elVal) {
							$elEach.removeClass('active');
						}
					});

					// Remove any current labels and add clicked item label if active
					$activeItems.empty();
					if ($el.hasClass('active')) {
						$activeItems.append($elLabel);
					}

					// Loop through each toggle and get all that are active
					$('.filter').each(function () {
						$elEach = $(this);
						if ($elEach.hasClass('active')) {
							$elEachVal = $elEach.attr('value');
							filterItems += '.' + $elEachVal;
						}
					});

					// Filter grid by active items
					$grid.isotope({
						filter: filterItems
					});

					// If no items found for filter selection
					$grid.on('layoutComplete', function (event, laidOutItems) {
						if (laidOutItems.length === 0) {
							$('.item-none').fadeIn('slow');
						} else {
							$('.item-none').fadeOut('fast');
						}
					});

				});

				$("img.lazy").lazyload({
					effect: "fadeIn"
				});

				$("#reset-filter").click(function (e) {
					e.preventDefault();
					$grid.isotope({
						filter: '*'
					});
					$('.selector-active-items').empty();
					$('.filter').removeClass('active');
				});

			}
		},
		'30_ways_to_goodness': {
			init: function () {
				function autoPlayYouTubeModal() {
					var trigger = $("body").find('[data-toggle="modal"]');
					trigger.click(function () {
						var theModal = $(this).data("target"),
							videoSRC = $(this).attr("data-theVideo"),
							videoSRCauto = videoSRC + "?autoplay=1";

						$(theModal + ' iframe').attr('src', videoSRCauto);
						$(theModal + ' button.close').click(function () {
							$(theModal + ' iframe').removeAttr('src', videoSRC);
						});

						$(theModal).on('hidden.bs.modal', function () {
							$(theModal + ' iframe').removeAttr('src', videoSRC);
						});
					});
				}

				$(document).ready(function () {
					autoPlayYouTubeModal();
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load our kitchen JS for products as well
	Sage.our_products = {
		init: Sage.our_kitchen.init
	};

	Sage.our_almonds = {
		init: Sage.home.init
	};

	Sage.our_growers = {
		init: Sage.home.init
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// jQuery function which hides the keyboard on mobile devices.
function hideKeyboard() {
	//this set timeout needed for case when hideKeyborad
	//is called inside of 'onfocus' event handler
	setTimeout(function () {

		//creating temp field
		var field = document.createElement('input');
		field.setAttribute('type', 'text');
		//hiding temp field from peoples eyes
		//-webkit-user-modify is nessesary for Android 4.x
		field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
		document.body.appendChild(field);

		//adding onfocus event handler for out temp field
		field.onfocus = function () {
			//this timeout of 200ms is nessasary for Android 2.3.x
			setTimeout(function () {

				field.setAttribute('style', 'display:none;');
				setTimeout(function () {
					document.body.removeChild(field);
					document.body.focus();
				}, 14);

			}, 200);
		};
		//focusing it
		field.focus();

	}, 50);
}
